/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

html,
body {
    height: 100%;
    font: 400 13px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important;
    /* font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 400; */
    line-height: 1.5;
    color: #666666 !important;
}

.form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 0px;
}

.btn-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 0px;
}

.btn-primary {
    color: #ffffff;
    background-color: #4a89dc;
}

table {
    border-collapse: collapse !important;
    border-spacing: 0 !important;
}

table thead tr th {
    color: rgba(0, 0, 0, 0.54) !important;
    font: 600 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.header {
    color: #666666;
    background-color: #fafafa;
}

.custom-drop-down {
    max-height: 200px;
    overflow-y: auto;
}